/* This example requires Tailwind CSS v2.0+ */
export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <p className="text-center text-lg font-semibold text-gray-600">
          Trusted by various organizations and partners
        </p>
        <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-2 lg:mt-8">
          <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
            <img
              className="max-h-22"
              src="https://viveca33101.us-east-1.linodeobjects.com/award.jpg"
              alt="Workcation"
            />
          </div>
          <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
            <img className="max-h-22" src="https://viveca33101.us-east-1.linodeobjects.com/cert-trans.png" alt="Mirage" />
          </div>
         
        </div>
      </div>
    </div>
  )
}
