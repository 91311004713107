/* This example requires Tailwind CSS v2.0+ */
const facilities = [
  {
    name: 'Facility',
    location: 'Somewhere',
    id: 1,
  },
  // More people...
]

export default function Example() {
  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='sm:flex sm:items-center'>
        <div className='my-10 sm:flex-auto'>
          <h1 className='text-3xl font-semibold text-gray-900'>
            Facilities List
          </h1>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
          {/* <button
              type='button'
              className='inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
            >
              Add user
            </button> */}
        </div>
      </div>
      <div className='flex flex-col mt-8'>
        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-xl font-semibold text-gray-900 sm:pl-6'
                    >
                      Computer Controlled Equipment
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
				  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
					eci JobBOSS²
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Solidworks
                    </td>
                  </tr>
				  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
					Harrington Group Calibration Recall
                    </td>
                  </tr>

                  <tr scope='col'>
                    <td className='py-3.5 pl-4 pr-3 text-left text-xl font-semibold text-gray-900 sm:pl-6'>
                      Lathes
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Okuma-LB-9 with TM-APT Programming Station
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Okuma Crown –5B with TM-APT Programming Station
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Okuma-LB-10 II M CNC w/OSP
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Doosan Lynx 220 LM
                    </td>
                  </tr>
				  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
					Okuma Genos L2000
                    </td>
                  </tr>
                  <tr scope='col'>
                    <td className='py-3.5 pl-4 pr-3 text-left text-xl font-semibold text-gray-900 sm:pl-6'>
                      Mills
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Okuma - Genos M560-V
                    </td>
                  </tr>
				  
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Okuma -VR-40 II Vertical Machine Center
                    </td>
                  </tr>
				  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
					Okuma - MC-V-4020 5 Axis
                    </td>
                  </tr>
				  
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Mazak -Vertical Center Nexus 410B 4/5 Axis
                    </td>
                  </tr>
                  <tr scope='col'>
                    <td className='py-3.5 pl-4 pr-3 text-left text-xl font-semibold text-gray-900 sm:pl-6'>
                      Inspection Equipment
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Mitutoyo BRT 504 CNC/CMM w/ Statmeasure Software 2.0 /
                      Cadmeasure 6000 Software V1
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Nikon Vision System – iNEXIVTP w/ CMM Manager Software
                      Ver.3.7
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Optical Gaging Product – 14” Contour Projector – 30”
                      available off-site
                    </td>
                  </tr>
				  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
					Mitutoyo SJ- 410 – Profilometer
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Surface Plate 3 x 5’
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Height Gage 12” Digital
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Digital Micrometers
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Digital Vernier Calipers
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Depth Micrometers
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Dial Indicators
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Gage Blocks
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Plain Plug Gages
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Gage Balls
                    </td>
                  </tr>
                  <tr scope='col'>
                    <td className='py-3.5 pl-4 pr-3 text-left text-xl font-semibold text-gray-900 sm:pl-6'>
                      Conventional Equipment
                    </td>
                  </tr>

                  <tr>
                    <td className='py-4 pl-4 pr-3 font-semibold text-lg text-gray-900 whitespace-nowrap sm:pl-6'>
                      Lathes
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Clausing-Colchester 11” 2.0 HP
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-lg font-semibold text-gray-900 whitespace-nowrap sm:pl-6'>
                      Mills
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Bridgeport-Vertical Auto Model 2J 1-1/2 HP
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Bridgeport-Vertical Auto Model 2J 1-1/2 HP w/ Digital
                      Readout
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-lg font-semibold text-gray-900 whitespace-nowrap sm:pl-6'>
                      Saws
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Startrite- Heavy Duty Bandsaw
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Dewalt Miter 12”
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-lg font-semibold text-gray-900 whitespace-nowrap sm:pl-6'>
                      Marking
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Hot Stamping Machine - Franklin 1020
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Engraving – Hermes Vanguard 3000
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      (1) Engraving – Hermes Vanguard Orbitor
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      (1) Engraving – Gravograph IS400
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-lg font-semibold text-gray-900 whitespace-nowrap sm:pl-6'>
                      Presses
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Dake Hand Press 4” Stroke
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-lg font-semibold text-gray-900 whitespace-nowrap sm:pl-6'>
                      Grinders
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Baldor 6”Pedestal Tool Grinder
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Harig 6 x 12 Ballways Surface Grinder
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-lg font-semibold text-gray-900 whitespace-nowrap sm:pl-6'>
                      Oven
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Grieve –HA 500
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-lg font-semibold text-gray-900 whitespace-nowrap sm:pl-6'>
                      Compressor
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Zeks HeatSink Dryer
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-lg font-semibold text-gray-900 whitespace-nowrap sm:pl-6'>
                      Others
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      Sharpertek Ultrasonic Cleaner
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pl-4 pr-3 text-md text-gray-900 whitespace-nowrap sm:pl-6'>
                      DigtalPro+ Ultrasonic Cleaner PS-40A
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
