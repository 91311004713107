
const people = [
  {
    name: 'Lisa Marsanico-Foley',
    role: 'President',
    imageUrl:
      'https://viveca33101.us-east-1.linodeobjects.com/lisa.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    bio: 'Lisa Foley obtained her B.A. in 1989 from Susquehanna University where she studied business and English. She began working summers at MMI in 1985, starting in the shop as her brother did. Upon her graduation, she returned to MMI full-time and worked in the office as an Administrator where she was responsible for the bookkeeping and processed customer orders. In 1991 she was promoted to Treasurer and became responsible for all financial aspects of MMI. At this point she computerized the office, converted MMI to an automated payroll system, and devised a shipping system which could be tracked electronically. When her father retired in 1992 she became Vice President. In this role Ms. Foley handled all business related leases and contracts, controlled all aspects of human resources, and monitored shop operations in absence of the operations manager. In 2005 Ms. Foley converted MMI from small business accounting software to a major shop floor control system, E2 by Shoptech. This enabled both her and Robert to closely monitor and track jobs, costs and purchases and increase their profitability. In 2006 she supervised the successful ISO 13485 certification process for MMI. In 2007 Ms. Foley assumed the office of President.',
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Robert G. Marsanico',
    role: 'Director',
    imageUrl:
      'https://viveca33101.us-east-1.linodeobjects.com/robert.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    bio: 'Upon graduating from Hobart, Robert then went on to Drexel University in 1984 with a B.S. in Chemical Engineering. Robert Marsanico began working full-time at MMI. He started in the shop learning how to operate the various CNC lathes and mills. In 1985 he became the shop supervisor. There he was responsible for employee scheduling, supervising production, and machine maintenance. In 1986 he was named Vice President of Manufacturing, responsible for all aspects of sales, production and quality assurance. He held this position until his father retired in 1992, at which time he assumed the role of President. He is currently Director.',
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Du Phan',
    role: 'Vice-President, Engineering',
    imageUrl:
      'https://viveca33101.us-east-1.linodeobjects.com/du-phan.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    bio: 'Mr. Phan received his M.S. in Chemical Engineering in 1986 from Drexel University in Philadelphia, Pennsylvania. He began his career at Marson Medical in the same year as a programmer. In 1990 he was appointed Vice President   of Engineering, the position he holds today.',
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Andre Fisher',
    role: 'Quality Assurance Manager',
    imageUrl:
      'https://viveca33101.us-east-1.linodeobjects.com/andre.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    bio: 'Mr. Fisher received his A.S. in Quality Engineering from Westchester Community College in 1989. He began his career at Marson Medical in 1996. He is responsible for ensuring our product meets or exceeds our customers requirements, maintains all quality records, and works closely with American Systems Registrar to maintain our ISO certification.',
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  
]

export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">About Our Team</h2>
            <p className="text-xl text-gray-500">
              
            </p>
          </div>
          <div className="lg:col-span-2">
            <ul
              role="list"
              className="space-y-12 sm:-mt-8 sm:space-y-0 sm:divide-y sm:divide-gray-200 lg:gap-x-8 lg:space-y-0"
            >
              {people.map((person) => (
                <li key={person.name} className="sm:py-8">
                  <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                    <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                      <img className="rounded-lg object-cover shadow-lg" src={person.imageUrl} alt="" />
                    </div>
                    <div className="sm:col-span-2">
                      <div className="space-y-4">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>{person.name}</h3>
                          <p className="text-blue-800">{person.role}</p>
                        </div>
                        <div className="text-lg">
                          <p className="text-gray-500">{person.bio}</p>
                        </div>
                        <ul role="list" className="flex space-x-5">
                          <li>
                            <a href={person.twitterUrl} className="text-gray-400 hover:text-gray-500">
                              <span className="sr-only">Twitter</span>
                              <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                              <span className="sr-only">LinkedIn</span>
                              <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                  fillRule="evenodd"
                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
