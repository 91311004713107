import logo from './logo.svg';
import './App.css';
import { Routes, Route, Link } from "react-router-dom";
import Landing from './pages/landing';
import Contact from './pages/contact';
import Team from './pages/team';
import Facilities from './pages/facilities';
import Recognitions from './pages/recognitions';
import Products from './pages/products';

function App() {
  return (
    <Routes >
      <Route path="/" element={<Landing/>} />
      <Route path="contact" element={<Contact />} />
      <Route path="products" element={<Products />} />
      <Route path="facilities" element={<Facilities />} />
      <Route path="recognitions" element={<Recognitions />} />
      <Route path="team" element={<Team />} />
  </Routes>
  );
}

export default App;
