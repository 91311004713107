import {
  ArrowUturnLeftIcon,
  ChatBubbleBottomCenterTextIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftRightIcon,
  DocumentChartBarIcon,
  HeartIcon,
  InboxIcon,
  PencilSquareIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  TrashIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"

const solutions = [
  {
    name: "Inbox",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: "#",
    icon: InboxIcon,
  },
  {
    name: "Messaging",
    description: "Speak directly to your customers in a more meaningful way.",
    href: "#",
    icon: ChatBubbleBottomCenterTextIcon,
  },
  {
    name: "Live Chat",
    description: "Your customers' data will be safe and secure.",
    href: "#",
    icon: ChatBubbleLeftRightIcon,
  },
  {
    name: "Knowledge Base",
    description: "Connect with third-party tools that you're already using.",
    href: "#",
    icon: QuestionMarkCircleIcon,
  },
]
const features = [
  {
    name: "Unlimited Inboxes",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.",
    icon: InboxIcon,
  },
  {
    name: "Manage Team Members",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.",
    icon: UsersIcon,
  },
  {
    name: "Spam Report",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.",
    icon: TrashIcon,
  },
  {
    name: "Compose in Markdown",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.",
    icon: PencilSquareIcon,
  },
  {
    name: "Team Reporting",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.",
    icon: DocumentChartBarIcon,
  },
  {
    name: "Saved Replies",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.",
    icon: ArrowUturnLeftIcon,
  },
  {
    name: "Email Commenting",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.",
    icon: ChatBubbleLeftEllipsisIcon,
  },
  {
    name: "Connect with Customers",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.",
    icon: HeartIcon,
  },
]
const metrics = [
  { id: 1, stat: "20", emphasis: "Employees", rest: "" },
  { id: 2, stat: "95%", emphasis: "Employee Retention", rest: "" },
  { id: 3, stat: "75%", emphasis: "Year on year Growth", rest: "" },
  { id: 4, stat: "100%", emphasis: "Productivity", rest: "" },
]
const footerNavigation = {
  solutions: [
    { name: "Marketing", href: "#" },
    { name: "Analytics", href: "#" },
    { name: "Commerce", href: "#" },
    { name: "Insights", href: "#" },
  ],
  support: [
    { name: "Pricing", href: "#" },
    { name: "Documentation", href: "#" },
    { name: "Guides", href: "#" },
    { name: "API Status", href: "#" },
  ],
  company: [
    { name: "About", href: "#" },
    { name: "Blog", href: "#" },
    { name: "Jobs", href: "#" },
    { name: "Press", href: "#" },
    { name: "Partners", href: "#" },
  ],
  legal: [
    { name: "Claim", href: "#" },
    { name: "Privacy", href: "#" },
    { name: "Terms", href: "#" },
  ],
  social: [
    {
      name: "Facebook",
      href: "#",
      icon: (props) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path
            fillRule='evenodd'
            d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z'
            clipRule='evenodd'
          />
        </svg>
      ),
    },
    {
      name: "Instagram",
      href: "#",
      icon: (props) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path
            fillRule='evenodd'
            d='M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z'
            clipRule='evenodd'
          />
        </svg>
      ),
    },
    {
      name: "Twitter",
      href: "#",
      icon: (props) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' />
        </svg>
      ),
    },
    {
      name: "GitHub",
      href: "#",
      icon: (props) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path
            fillRule='evenodd'
            d='M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z'
            clipRule='evenodd'
          />
        </svg>
      ),
    },
    {
      name: "Dribbble",
      href: "#",
      icon: (props) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path
            fillRule='evenodd'
            d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z'
            clipRule='evenodd'
          />
        </svg>
      ),
    },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Example() {
  return (
    <div className='bg-white'>
      <main>
        {/* Hero section */}
        <div className='relative'>
          <div className='absolute inset-x-0 bottom-0 bg-gray-100 h-1/2' />
          <div className='m-8 mx-auto max-w-7xl sm:px-6 lg:px-8'>
            <div className='relative shadow-xl sm:overflow-hidden sm:rounded-2xl'>
              <div className='absolute inset-0'>
                <video
                  autoPlay
                  muted
                  loop
                  id='hero-video'
                  className='object-cover w-full h-full sm:rounded-2xl'
                >
                  <source
                    src='https://viveca33101.us-east-1.linodeobjects.com/testvideo.mp4'
                    type='video/mp4'
                  />
                </video>
                <div className='absolute inset-0 bg-gradient-to-r from-blue-500 to-blue-800 mix-blend-multiply' />
              </div>

              <div className='relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8'>
                <div className='flex justify-center col-span-1 md:col-span-2 lg:col-span-1'>
                  <img
                    className='h-auto max-w-xs'
                    src='https://viveca33101.us-east-1.linodeobjects.com/marsonmedicalWhite.svg'
                    alt='Mirage'
                  />
                </div>
                <h1 className='text-4xl font-bold tracking-tight text-center sm:text-5xl lg:text-6xl'>
                  <span className='block text-white'>
                    We are dedicated to the
                  </span>
                  <span className='block text-indigo-200'>
                    Medical Industry in the
                  </span>
                  <span className='block text-indigo-200'>
                    Machining of Plastics from
                  </span>
                  <span className='block text-indigo-200'>
                    Prototype to Production
                  </span>
                </h1>
                <p className='max-w-lg mx-auto mt-6 text-xl text-center text-indigo-200 sm:max-w-3xl'>
                  Marson Medical is a contract manufacturing company for the
                  medical and plastics industry. Offering conventional 4 and 5
                  axis machining, we can handle any and all of your plastic
                  needs. For over 40 years Marson Medical has manufactured all
                  forms of plastics with our state of the art equipment; going
                  further to meet the needs our expanding customer base. Marson
                  Medical also provides services in: Heat Treatment, Hot
                  Stamping, Engraving and Laser Marking.
                </p>
                <div className='max-w-sm mx-auto mt-10 sm:flex sm:max-w-none sm:justify-center'>
                  <div className='space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5 sm:space-y-0'>
                    <a
                      href='#'
                      className='flex items-center justify-center px-4 py-3 text-base font-medium text-blue-700 bg-white border border-transparent rounded-md shadow-sm hover:bg-indigo-50 sm:px-8'
                    >
                      Learn More
                    </a>
                    <a
                      href='/contact'
                      className='flex items-center justify-center px-4 py-3 text-base font-medium text-white bg-blue-500 border border-transparent rounded-md shadow-sm bg-opacity-60 hover:bg-opacity-70 sm:px-8'
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Logo Cloud */}
        <div className='bg-gray-100'>
          <div className='px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8'>
            <p className='text-base font-semibold text-center text-gray-500'>
              Trusted orthopedic implants and instrument systems since 1981
            </p>
           
          </div>
        </div>

        {/* Alternating Feature Sections */}
        <div className='relative pt-16 pb-32 overflow-hidden'>
          <div
            aria-hidden='true'
            className='absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100'
          />
          <div className='relative'>
            <div className='lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8'>
              <div className='max-w-xl px-4 mx-auto sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0'>
                <div>
                  
                  <div className='mt-6'>
                    <h2 className='text-3xl font-bold tracking-tight text-gray-900'>
                      Production services to manufacturers and marketers
                    </h2>
                    <p className='mt-4 text-lg text-gray-500'>
                      Marson Medical, Inc. has been providing contract
                      production services to manufacturers and marketers of
                      orthopedic implants and instrument systems since 1981.
                    </p>
                    <div className='mt-6'>
                      <a
                        href='/contact'
                        className='inline-flex px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-gradient-to-r from-blue-600 to-blue-800 bg-origin-border hover:from-blue-700 hover:to-cyan-700'
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
                <div className='pt-6 mt-8 border-t border-gray-200'>
                  <blockquote>
                    <div>
                      <p className='text-base text-gray-500'>
                        &ldquo;We love that our company was started in 1981 by
                        our father. We continue his vision of producing quality
                        products and giving excellent service. Most of our
                        employees have started their careers right out of school
                        with us and continue to grow at Marson Medical.&rdquo;
                      </p>
                    </div>
                    <footer className='mt-3'>
                      <div className='flex items-center space-x-3'>
                        <div className='flex-shrink-0'>
                          <img
                            className='w-6 h-6 rounded-full'
                            src='https://viveca33101.us-east-1.linodeobjects.com/lisa.jpg?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80'
                            alt=''
                          />
                        </div>
                        <div className='text-base font-medium text-gray-700'>
                          Lisa Foley, President
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
              <div className='mt-12 sm:mt-16 lg:mt-0'>
                <div className='pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0'>
                  <img
                    className='w-full shadow-xl rounded-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none'
                    src='https://viveca33101.us-east-1.linodeobjects.com/machine1.jpg'
                    alt='Inbox user interface'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='mt-24'>
            <div className='lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8'>
              <div className='max-w-xl px-4 mx-auto sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0'>
                <div>
                  <div>
                    <span className='flex items-center justify-center w-12 h-12 rounded-md bg-gradient-to-r from-blue-600 to-blue-800'>
                      <SparklesIcon
                        className='w-6 h-6 text-white'
                        aria-hidden='true'
                      />
                    </span>
                  </div>
                  <div className='mt-6'>
                    <h2 className='text-3xl font-bold tracking-tight text-gray-900'>
                      Contributing to our ongoing success
                    </h2>
                    <p className='mt-4 text-lg text-gray-500'>
                      Our specialty is the precision CNC machining of UHMWPE
                      acetabular liners and tibial inserts for the medical
                      industry. Contributing to our ongoing success as a
                      manufacturer of plastic medical components are the
                      activities of our dedicated staff of experienced
                      Manufacturing, Engineering and Quality Assurance
                      personnel.
                    </p>
                    <div className='mt-6'>
                      <a
                        href='/contact'
                        className='inline-flex px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-gradient-to-r from-blue-600 to-blue-800 bg-origin-border hover:from-blue-700 hover:to-cyan-700'
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-12 sm:mt-16 lg:col-start-1 lg:mt-0'>
                <div className='pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0'>
                  <img
                    className='w-full shadow-xl rounded-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none'
                    src='https://viveca33101.us-east-1.linodeobjects.com/machine2.jpg'
                    alt='Customer profile user interface'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Stats section */}
        <div className='relative bg-gray-900'>
          <div className='absolute inset-x-0 bottom-0 h-80 xl:top-0 xl:h-full'>
            <div className='w-full h-full xl:grid xl:grid-cols-2'>
              <div className='h-full xl:relative xl:col-start-2'>
                <img
                  className='object-cover w-full h-full opacity-25 xl:absolute xl:inset-0'
                  src='https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100'
                  alt='People working on laptops'
                />
                <div
                  aria-hidden='true'
                  className='absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r'
                />
              </div>
            </div>
          </div>
          <div className='max-w-4xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:grid-cols-2 xl:gap-x-8'>
            <div className='relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24'>
              <h2 className='text-base font-semibold'>
                <span className='text-transparent bg-gradient-to-r from-blue-300 to-blue-300 bg-clip-text'>
                  Quality Statement
                </span>
              </h2>
              <p className='mt-3 text-3xl font-bold tracking-tight text-white'>
			  &ldquo;Marson Medical, Inc. provides high
                precision machined products to a wide range of customers. For
                every product we supply, our goal is to understand and meet the
                customer's requirements while at the same time delivering the
                product on time and at a competitive price.&ldquo;
              </p>
              <p className='mt-5 text-lg text-gray-300'>
                Products such as orthopedic implants, instruments, UHMWPE
                acetabular liners and tibial inserts are available. Capabilities
                include prototype and production CNC precision machining and
                manufacturing services.
              </p>
              <div className='grid grid-cols-1 mt-12 gap-y-12 gap-x-6 sm:grid-cols-2'>
                {metrics.map((item) => (
                  <p key={item.id}>
                    <span className='block text-2xl font-bold text-white'>
                      {item.stat}
                    </span>
                    <span className='block mt-1 text-base text-gray-300'>
                      <span className='font-medium text-white'>
                        {item.emphasis}
                      </span>{" "}
                      {item.rest}
                    </span>
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>

		
	  <div className="bg-white py-16 lg:py-24">
		<div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
		  <div className="relative overflow-hidden rounded-xl bg-blue-500 py-24 px-8 shadow-2xl lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-16">
			<div className="absolute inset-0 opacity-50 mix-blend-multiply saturate-0 filter">
			  <img
				src="https://viveca33101.us-east-1.linodeobjects.com/photo-1601381718415-a05fb0a261f3.jpg?ixid=MXwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8ODl8fHxlbnwwfHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1216&q=80"
				alt=""
				className="h-full w-full object-cover"
			  />
			</div>
			<div className="relative lg:col-span-1">
			  <img className="h-12 w-auto" src="https://viveca33101.us-east-1.linodeobjects.com/marsonmedicalWhite.svg" alt="" />
			  <blockquote className="mt-6 text-white">
				<p className="text-xl font-medium sm:text-2xl">
				Our commitment to attract and retain talent, as well as our continued innovation in healthcare within the region and around the world
				</p>
				
			  </blockquote>
			</div>
		  </div>
		</div>
	  </div>
	)
  
  




        {/* CTA Section */}
        <div className='bg-white'>
          <div className='max-w-4xl px-4 py-16 mx-auto sm:px-6 sm:py-24 lg:flex lg:max-w-7xl lg:items-center lg:justify-between lg:px-8'>
            <h2 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
              <span className='block'>Ready to get started?</span>
              <span className='block pb-1 -mb-1 text-transparent bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text'>
                Get in touch
              </span>
            </h2>
            <div className='relative mt-12 sm:mx-auto sm:max-w-lg lg:col-span-6 lg:mx-0 lg:mt-0 lg:flex lg:max-w-none lg:items-center'>
              <svg
                className='absolute top-0 origin-top transform scale-75 -translate-x-1/2 -translate-y-8 left-1/2 sm:scale-100 lg:hidden'
                width={640}
                height={784}
                fill='none'
                viewBox='0 0 640 784'
                aria-hidden='true'
              >
                <defs>
                  <pattern
                    id='4f4f415c-a0e9-44c2-9601-6ded5a34a13e'
                    x={118}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits='userSpaceOnUse'
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className='text-gray-200'
                      fill='currentColor'
                    />
                  </pattern>
                </defs>
                <rect
                  y={72}
                  width={640}
                  height={640}
                  className='text-gray-50'
                  fill='currentColor'
                />
                <rect
                  x={118}
                  width={404}
                  height={784}
                  fill='url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)'
                />
              </svg>
              <div className='relative w-full mx-auto rounded-lg shadow-lg lg:max-w-md'>
                <button
                  type='button'
                  className='relative block w-full overflow-hidden bg-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-800 focus:ring-offset-2'
                >
                  <span className='sr-only'>Watch our video to learn more</span>
                  <video
				  			className='object-cover w-full'
							src='https://viveca33101.us-east-1.linodeobjects.com/mm-outromovie.mp4'
                    alt=''
                    autoPlay
					loop
					muted
						/>
                  <span
                    className='absolute inset-0 flex items-center justify-center w-full h-full'
                    aria-hidden='true'
                  >
                   
                  </span>
                </button>
              </div>
            </div>
            <div className='mt-6 space-y-4 sm:flex sm:space-y-0 sm:space-x-5'>
              <a
                href='#'
                className='flex items-center justify-center px-4 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-gradient-to-r from-blue-600 to-blue-800 bg-origin-border hover:from-blue-700 hover:to-blue-800'
              >
                Learn more
              </a>
              <a
                href='/contact'
                className='flex items-center justify-center px-4 py-3 text-base font-medium text-blue-800 border border-transparent rounded-md shadow-sm bg-indigo-50 hover:bg-indigo-100'
              >
                Get started
              </a>
            </div>
          </div>
        </div>
      </main>

      <footer className='bg-gray-50' aria-labelledby='footer-heading'>
        <h2 id='footer-heading' className='sr-only'>
          Footer
        </h2>
      </footer>
    </div>
  )
}
