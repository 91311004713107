/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/


const files = [
  {
    title: "Hips Implant",
    size: "Material Type: Vitamin E",
    source:
      "https://viveca33101.us-east-1.linodeobjects.com/mm-product1.jpg?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },
  {
	title: "Hips Implant",
    size: "Material Type: UHMWPE",
    source:
      "https://viveca33101.us-east-1.linodeobjects.com/mm-product2.jpg?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },
  {
    title: "Patella",
    size: "Material Type: Acetal Copolymer",
    source:
      "https://viveca33101.us-east-1.linodeobjects.com/mm-product3.jpg?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },
  {
    title: "Sleeve",
    size: "Material Type: UHMWPE",
    source:
      "https://viveca33101.us-east-1.linodeobjects.com/mm-product4.jpg?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },
  {
    title: "Trial Hip",
    size: "Material Type: Ultem",
    source:
      "https://viveca33101.us-east-1.linodeobjects.com/mm-product5.jpg?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },
  {
    title: "Trial Knee",
    size: "Material Type: Acetal copolymer",
    source:
      "https://viveca33101.us-east-1.linodeobjects.com/mm-product6.jpg?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },
  {
    title: "Knee Implant",
    size: "Material Type: Vitamin E",
    source:
      "https://viveca33101.us-east-1.linodeobjects.com/mm-product7.jpg?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },
  {
    title: "Knee Implant",
    size: "Material Type: UHMWPE",
    source:
      "https://viveca33101.us-east-1.linodeobjects.com/mm-product8.jpg?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },

  {
    title: "Trial",
    size: "Material Type: Ultem",
    source:
      "https://viveca33101.us-east-1.linodeobjects.com/mm-product9.jpg?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },

  {
    title: "Trial",
    size: "Material Type: Ultem / Radel",
    source:
      "https://viveca33101.us-east-1.linodeobjects.com/mm-product10.jpg?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },




  // More files...
]

export default function Example() {
  return (
    <ul
      role='list'
      className='grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8'
    >
      {files.map((file) => (
        <li key={file.source} className='relative'>
          <div className='group aspect-w-10 aspect-h-7 mt-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100'>
            <img
              src={file.source}
              alt=''
              className='pointer-events-none object-cover group-hover:opacity-75'
            />
            <button
              type='button'
              className='absolute inset-0 focus:outline-none'
            >
              <span className='sr-only'>View details for {file.title}</span>
            </button>
          </div>
          <p className='pointer-events-none ml-10 mt-2 block truncate text-sm font-medium text-gray-900'>
            {file.title}
          </p>
          <p className='pointer-events-none ml-10 block text-sm font-medium text-gray-500'>
            {file.size}
          </p>
        </li>
      ))}
    </ul>
  )
}
